<template>
  <b-card>
    <b-row>
      <h6 class="mx-1 mb-2">
        {{ $t('NOTIFICATIONS') }}
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="id-send_weekly_report"
          v-model="notificationData.isSendDailyEmail"
          name="check-button"
          switch
          inline
        >
          <span>{{ $t('SEND_WEEKLY_REPORT') }}</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-group :label="$t('NOTIFICATION_METHOD')">
          <b-form-radio-group
            id="notification-method-radio-group"
            v-model="selectedPreference"
            name="contactPreference"
            :options="[
              { text: $t('EMAIL'), value: 'email' },
              { text: $t('SMS'), value: 'sms' }
            ]"
          />
        </b-form-group>
      </b-col>
      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="onSubmit()"
        >
          {{ $t('BUTTON.SAVE_CHANGES') }}
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BFormRadioGroup,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BFormRadioGroup,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
    saveOrUpdate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.notificationData)),
      selectedPreference: false,
    }
  },
  watch: {
    notificationData: {
      handler() {
        this.initializePreference()
      },
      deep: true,
      immediate: true,
    },
    selectedPreference(newValue) {
      this.notificationData.isEmailPreferred = newValue === 'email'
      this.notificationData.isSmsPreferred = newValue === 'sms'
    },
  },
  mounted() {
    this.initializePreference()
  },
  methods: {
    onSubmit() {
      this.saveOrUpdate(this.notificationData)
    },
    initializePreference() {
      // eslint-disable-next-line no-nested-ternary
      this.selectedPreference = this.notificationData.isEmailPreferred
        ? 'email'
        : this.notificationData.isSmsPreferred
          ? 'sms'
          : ''
    },
  },
}
</script>
